import { css } from '@emotion/core'

const layout = css`
  body,
  html {
    height: 100%;
  }

  body > div,
  body > div > div {
    display: flex;
    min-height: 100%;
    width: 100%;
  }
`

export default layout
