import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Section } from '../styled'

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          heading
          description
          qualifications
        }
      }
    }
  `)

  return (
    <Section>
      <h2>{data.site.siteMetadata.heading}</h2>
      <p><small>{data.site.siteMetadata.description}</small></p>
      <p><small>{data.site.siteMetadata.qualifications}</small></p>
    </Section>
  )
}

export default Header
