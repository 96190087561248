import React from 'react'
import { Global, css } from '@emotion/core'

import normalize from './normalize'
import fonts from './fonts'
import layout from './layout'
import type from './type'

const GlobalStyles = () => (
  <Global styles={css(normalize, fonts, layout, type)} />
)

export default GlobalStyles
