import React from 'react'
import { css } from '@emotion/core'
import DotLink from '../dot-link'
import InstagramIcon from '../icons/instagram'
import { COLOURS } from '../../constants'

const styles = {
  icon: css`
    display: block;
    color: ${COLOURS.white};
    width: 30px;
    height: 30px;
  `,
}

const Footer = () => {
  return (
    <DotLink url={'https://www.instagram.com/bekdileo/'}>
      <InstagramIcon css={styles.icon} />
    </DotLink>
  )
}

export default Footer
