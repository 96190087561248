import { css } from '@emotion/core'
import { COLOURS } from '../../constants'

export const dot = css`
  padding: 12px;
  border-radius: 50%;
  background-color: ${COLOURS.red};
  display: block;
  box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.4);
  &:hover {
    box-shadow: 0px 0px 6px rgba(31, 31, 31, 0.8);
  }
`
