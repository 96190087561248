import { css } from '@emotion/core'
import { COLOURS } from '../../constants/'

const type = css`
  .textLink {
    color: ${COLOURS.red};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .title {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }
`

export default type
