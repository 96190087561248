import React from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Footer from '../components/footer'
import Header from '../components/header'

const styles = {
  content: css`
    min-height: 200px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
  `,
}

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Page Not fount" />
      <Header />
      <div css={styles.content}>
        <h3>Not Found</h3>
        <p>
          <small>
            Sorry, the page you are looking for doesn't exist{' '}
            <Link className={'textLink'} to={'/'}>
              try here.
            </Link>
          </small>
        </p>
      </div>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage
