import { css } from '@emotion/core'

import RelativeFauxWoff from '../../fonts/relative-faux-webfont.woff'
import RelativeFauxWoff2 from '../../fonts/relative-faux-webfont.woff2'

const fonts = css`
  @font-face {
    font-family: 'relativefaux';
    font-weight: normal;
    font-style: normal;
    src: url(${RelativeFauxWoff2}) format('woff2');
    src: url(${RelativeFauxWoff}) format('woff');
  }

  body {
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  }

  h1,
  h2,
  h3 {
    font-family: relativefaux;
    margin: 0 0 10px;
  }

  p {
    margin: 0 0 10px;
  }
`
export default fonts
