import React from 'react'

import * as styles from './styles'

const DotLink = ({ url, children }) => {
  return (
    <a
      title="instagram"
      css={styles.dot}
      rel="noopener noreferrer"
      href={url}
      target="_blank"
    >
      {children}
    </a>
  )
}

export default DotLink
