import styled from '@emotion/styled'
import { MEDIA_QUERY } from '../../constants'

export const Section = styled.div`
  width: 100%;
  margin-bottom: 16px;
  max-width: 400px;
  ${MEDIA_QUERY.aboveSm} {
    max-width: 600px;
  }
`