export const COLOURS = {
  red: '#ac4b40',
  boulder: '#6C6C6C',
  midGrey: '#A5A5A5',
  grey: '#E5E5E5',
  lightGrey: '#F8F8F8',
  white: '#FFFFFF',
}

// export const boulder = '#6C6C6C'
// export const midGrey = '#A5A5A5'
// export const grey = '#E5E5E5'
// export const lightGrey = '#F8F8F8'
// export const white = '#FFFFFF'

export const BREAKPOINTS = {
  sm: 768,
  md: 1024,
  lg: 1600,
}

export const MEDIA_QUERY = {
  aboveSm: `@media screen and (min-width: ${BREAKPOINTS.sm}px)`,
  aboveMd: `@media screen and (min-width: ${BREAKPOINTS.md}px)`,
  aboveLg: `@media screen and (min-width: ${BREAKPOINTS.lg}px)`,
}
