import React from 'react'
import PropTypes from 'prop-types'

import { css } from '@emotion/core'

import GlobalStyles from './global-styles'

const styles = {
  main: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 40px 20px;
  `,
}

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <div css={styles.main}>{children}</div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
